import React from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { DatePicker } from 'antd'
import { Button } from 'reactstrap'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'

export default function AdvanceReportFiltersForm({ onSubmit, loading, register, errors, setValue, watch }) {

    const rangePresets = [
        {
          label: 'Últimos 7 Días',
          value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
          label: 'Últimos 14 Días',
          value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
          label: 'Mes Actual',
          value: [dayjs().startOf('month'), dayjs().endOf('month')],
        },
        {
          label: 'Mes Pasado',
          value: [dayjs().add(-1, 'M').startOf('month'), dayjs().add(-1, 'M').endOf('month')],
        },
    ];

    return (
        <React.Fragment>
            <Form onSubmit={onSubmit}>
                <Form.Group className="form-group">
                    <Form.Label>Fecha de Envió de Campaña</Form.Label>
                    <RangePicker 
                        presets={rangePresets}
                        value={[
                            dayjs(watch('sent_at_from'), dateFormat),
                            dayjs(watch('sent_at_until'), dateFormat)
                        ]}
                        onChange={(e, dates) => {
                            setValue('sent_at_from', dates[0])
                            setValue('sent_at_until', dates[1])
                        }}
                        allowClear={false}
                    />
                    <Form.Control {...register('sent_at_from', { required:true })} style={{display:'none'}} />
                    { errors.sent_at_from &&  <Form.Text className='text-warning'>Este campo es requerido</Form.Text>}
                    <Form.Control {...register('sent_at_until', { required:true })} style={{display:'none'}} />
                    { errors.sent_at_until &&  <Form.Text className='text-warning'>Este campo es requerido</Form.Text>}
                </Form.Group>
                {/* <Form.Group className="form-group">
                    <Form.Label>Fecha de Compra en Facturas <small><em>(Solo afecta reporte de facturas)</em></small></Form.Label>
                    <RangePicker 
                        presets={rangePresets}
                        value={[
                            dayjs(watch('sold_at_from'), dateFormat),
                            dayjs(watch('sold_at_until'), dateFormat)
                        ]}
                        onChange={(e, dates) => {
                            setValue('sold_at_from', dates[0])
                            setValue('sold_at_until', dates[1])
                        }}
                    />
                    <Form.Control {...register('sold_at_from', { required:true })} style={{display:'none'}} />
                    { errors.sold_at_from &&  <Form.Text className='text-warning'>Este campo es requerido</Form.Text>}
                    <Form.Control {...register('sold_at_until', { required:true })} style={{display:'none'}} />
                    { errors.sold_at_until &&  <Form.Text className='text-warning'>Este campo es requerido</Form.Text>}
                </Form.Group> */}
                {/* <Form.Group className="form-group">
                    <Form.Label>Campañas Específicas</Form.Label>
                </Form.Group> */}
                {/* <Form.Group className="form-group">
                    <Form.Label>Categorías de Campañas</Form.Label>
                </Form.Group> */}
                {/* <Form.Group className="form-group">
                    <Form.Label>Regionales <small><em>(Solo afecta tabla de facturas)</em></small></Form.Label>
                </Form.Group> */}
                <Form.Group className="form-group">
                    <Form.Label>Código de Tienda <small><em>(Solo afecta reporte de facturas)</em></small></Form.Label>
                    <Form.Control 
                        {...register('store_code', {})}
                        placeholder='Escriba aquí...'
                    />
                </Form.Group>
                <Button type="submit" color='primary' disabled={loading}>
                    { loading && <Spinner animation='border' size="sm" className='mr-5' /> }
                    Generar Reporte
                </Button>
            </Form>
        </React.Fragment>
    )
}